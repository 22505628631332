@import './../../colors.scss';
@import './../../components/loading_component/loading_component.scss';

.preview-header {
    font-weight: 700;
    font-size: 16px;
    padding: 5px;
    border-bottom: 2px solid red;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.done-button{
    background: $color-green;
    cursor: pointer;
    font-weight: bold;
    line-height: 22px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    padding-top: 16px;
    justify-content: center;
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    text-align: center;
    font-size: 17px;
    box-shadow: 0px 0px 20px #000;
    &:hover{
        background-color: white;
        color:black;
    }
}