@import './../toggle_menu_component/toggle_menu_component.scss';
@import './../study_state_component/study_state_component.scss';

.reference.grid {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(100px,1fr));
    height: calc(100vh - 116px);
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 10px;

    .box {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-content: center;
        align-content: center;
        -webkit-flex-direction: column;
        flex-direction: column;
        text-align: center;
    }

    .column {
        width: 100%;
    }

    .header, .view-short {
        font-weight: 700;
    }

    .header {
        font-size: 16px;
        padding: 5px;
        border-bottom: 2px solid white;
        margin: 10px 0px;
        width: 100%;
        color: white;
    }

    .view {
        border: 1px solid white;
        border-top: 0 solid white;
        padding: 15px 10px;
        -webkit-align-items: center;
        align-items: center;
        -webkit-align-content: center;
        align-content: center;
        flex-direction: column;
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;

        .view-image {
            margin: 2px;
            position: relative;

            img {
                margin: 0 auto;
            }
        }

        .view-tag {
            cursor: pointer;
            border: 2px solid white;
            border-radius: 5px;
            position: absolute;
            bottom: 16px;
            right: 6%;
            background-color: rgba(35,35,35,.8666666666666667);
            color: #f5f5f5;
            padding: 4px;
            text-align: center;
            font-size: 12px;

            .view-tag-short {
                padding: 0 2px;
                text-align: center;
            }

            .view-tag-long {
                visibility: hidden;
                width: 100px;
                background-color: #222;
                color: #fff;
                text-align: center;
                padding: 6px;
                border-radius: 6px;
                z-index: 100000;
                position: absolute;
                font-size: 9px;
                bottom: 150%;
                right: -2px;
            }

            .view-tag-long::after {
                content: " ";
                position: absolute;
                top: 100%; /* At the bottom of the tooltip */
                right: 10%;
                border-width: 5px;
                border-style: solid;
                border-color: #222 transparent transparent transparent;
            }
        }
        .view-tag:hover .view-tag-long {
            visibility: visible;
        }

        .view-long {
            font-style: italic;
            font-size: 12px;
            color: grey;
            margin: 5px 0 10px;
        }
    }
    .view .required-empty {
        font-size: 15px;
        font-weight: bold;
        background-color: black;
        max-width: 470px;
        margin: 0 auto;
        padding: 30px 0 35px;

        .view-long {
            color: white;
            font-size: 15px;
        }
    }

    .choose-button {
        margin: 0 auto;
        width: fit-content;
        font-size: 13px;
        font-style: normal;
        padding: 3px 10px;
        font-weight: 700;
        border-radius: 10px;
        color: #000;
        cursor: pointer;
    }

    .status-icons {
        position: absolute;
        bottom: 10px;
        left: 6%;
        display: flex;
        padding: 2px 5px;
        background-color: rgba(20, 20, 20, 0.8);
        border-radius: 20px;
        border: 2px solid white;

        .status-icon {
            width: 25px;
            height: 24px;
            margin: 4px;
            border: 3px solid white;
            border-radius: 25px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .status-icons.empty {
        border-color: red;
        height: 40px;
        width: 40px;
    }
}