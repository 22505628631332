@import './../node_modules/foundation-apps/scss/foundation.scss';
@import './../node_modules/carbon-components/css/carbon-components.css';
@import './ui/pages/login_page/login_page.scss';
@import './ui/pages/accept_terms_page/accept_terms_page.scss';
@import './ui/pages/user_page/user_page.scss';
@import './ui/pages/prisma_pages/prisma_pages.scss';
@import './ui/pages/tasks_page/tasks_page.scss';
@import './ui/pages/studies_page/studies_page.scss';
@import './ui/pages/preview_page/preview_page.scss';
@import './ui/pages/overview_page/overview_page.scss';
@import './ui/pages/reference_page/reference_page.scss';
@import './ui/pages/selection_page/selection_page.scss';
@import './ui/pages/cycletiming_page/cycletiming_page.scss';
@import './ui/pages/segmentation_page/segmentation_page.scss';
@import './ui/pages/validation_page/validation_page.scss';
@import './ui/pages/measurements_page/measurements_page.scss';
@import './ui/colors.scss';
@import './App.scss';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $color-normal-4;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

// Change scrollbar style
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgb(94, 93, 93);
    background-color:rgb(94, 93, 93);
    width: 4px;
    height: 6px;
}

::-webkit-scrollbar
{
	  background-color:$color-main;// black;
    width: 4px;
    height: 6px;
}

::-webkit-scrollbar-thumb
{
	background-color: $color-main;// rgb(94, 93, 93);
    border: 0.5px solid $color-main;//rgb(94, 93, 93);
    width: 4px;
    height: 6px;
}