
.user-page{

    background-color:rgb(39, 40, 34);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .platform-name.medium {
        width: 325px;
        height: 95px;
    }

    .button{

        font-size: 12pt;
        color:white;
        width: 300px;
        height: 300px;
        margin-top: 60%;
        border-radius: 50%;
        background-color: $color-normal;
        padding: 60px;
        padding-left: 90px;
        padding-top:50px;
        border: 5px solid transparent;
        overflow: hidden;
        z-index: 5;

        .play-button-svg{
            margin-left: 0px;
            stroke: white;
            fill:white;
            width:150px;
            height:150px;
        }

        &:hover{
            background-color: transparent;
            border: 5px solid $color-normal;
        }
    }

    .button_user_type_selectable{
        width: 250px;
        height: 250px;
        margin: auto;
        border-radius: 50%;
        border: 5px solid transparent;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
       
        &:hover{
            background-color: transparent;
            border: 5px solid white;
        }
        .button_user_type_text{
            font-size: 18px;
            font-weight: bold;
        }
        .button_user_type_description{
            font-size: 14px;
            line-height: 20px;
            margin-top: 7px;
        }
    }
    
    .platform-name{
        top: 20px;
        left: 20px;
        position: fixed;
        font-size: 20px;
        z-index: 1000;
        color: $color-normal;
        font-weight: bold;
    }

    .login-text{
        width: 100vw;
        margin-bottom: 60px;
        text-align: center;
        font-size: 26px;
        color: white;
        font-weight: bold;
    }

    
}
.user-types{
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    margin: 0 30px;
    gap: 30px;
    text-align: center;
    justify-content: center;
}