.pl-component-loading{
    .pl-component-loading-spinner{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cancel-button{
        color:red;
        cursor: pointer;
        padding: 10px;
        text-align: center;
        z-index: 10000000;
        font-size: 24px;

    }
    
    .progress_percent{
        font-weight: bold;
        font-size: 20px;
        position: absolute;
        color: #fbb;
    }
}

