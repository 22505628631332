@import './../../segmentation_component/segmentation_component.scss';

// GENERAL
.viewer:hover .video-controls {
    opacity: 1;
}

// ANALYSIS
.analysis.viewer {
    height: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    position: relative;
    background-color: black;
    
    .dicomImage {
        width: 75vw;
        height: 85vh;
        background-color: black;
        position: relative;

        .loading-dicom {
            position: absolute;
            width: 85vw;
            height: 85vh;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: black;
            top: 0px;
            z-index: 0;

            .note {
                margin-top: 25px;
                font-style: italic;
                font-size: 13px;
            }
        }

        &.selection_analysis {
            width: 70vw;
            height: 70vh;

            .loading-dicom {
                width: 70vw;
                height: 70vh;
            }
        }

        &.validation {
            width: 37vw;
            height: 37vh;

            .loading-dicom {
                width: 37vw;
                height: 37vh;
            }
        }

        &.comments {
            width: 37vw;
            height: 35vh;

            .loading-dicom {
                width: 37vw;
                height: 35vh;
            }
        }

        &.measurements {
            width: 10vw;
            height: 14vh;

            .loading-dicom {
                width: 10vw;
                height: 14vh;
            }
        }
    }
}

// CONTROLS
.video-controls {
    position: absolute;
    bottom: 10%;
    width: 730px;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 30px 1fr 50px 30px;
    opacity: 0;
    transition: opacity 0.2s ease-in;
}
.play-stop {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: #223abd;
    text-align: center;
    padding-top: 7px;
}
.timeline {
    margin: 0 7px 0 12px;
}
.markers {
    position: absolute;
    top: 30px;
}
.frame-marker {
    position: absolute;
    width: 80px;
    text-align: center;
    padding: 5px 3px 3px;
    line-height: 15px;
    border-radius: 6px;
    z-index: 100000;
    cursor: pointer;
    font-size: 11px;
    &::after {
        content: " ";
        position: absolute;
        top: -10px;
        left: 5px;
        border-width: 5px;
        border-style: solid;
    }
}
.frame-marker.diastole {
    background-color: #0ff;
    color: #000;
    &::after {
        border-color: transparent transparent #0ff transparent;
    }
}
.frame-marker.systole{
    background-color: #00f;
    color: #fff;
    &::after {
        border-color: transparent transparent #00f transparent;
    }
}
.frame-counter {
    align-self: center;
    text-align: right;
    font-size: 10pt;

    span {
        background-color: black;
        padding: 5px;
    }
}
.snapshot-button {
    display: none;
    margin-left: 10px;
    align-self: center;

    &.active {
        display: block;
        cursor: pointer;
    }

    svg { 
        fill: #36f;
    }
}