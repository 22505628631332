$color-normal:#b71c1c;//#5C4EE5;
$color-normal-2:#8c83ea;
$color-normal-3:#5596e6;
$color-normal-4:#00000029;
$color-normal-5:#8c83ea30;
$color-un-selected:#ffffff63;
$color_shadow:rgba(0, 0, 0, 0.16);
$color_shadow-2:#00000047;
$color_shadow_3:#00000038;
$rkt_viewer_patient_line_header_bg_color:#c62828;//#5C4EE5;
$rkt_viewer_patient_line_header_color:#FFFFFF;
$rkt_viewer_patient_line_viewer_bg_color:#21211f;
$rkt_viewer_patient_line_wiewer_bg_color_modal:#21211ff0;


$color-1:#5C4EE5;
$color-2:#4C6085;
$color-3:#39A0ED;
$color-4:#414361;
$color-5:#2A2D43;
$color-6:#191919;
$color-7:#ffffff6e;
$color-8:#403a81;
$color-9:#1d1d1d;
$color-10:#5c4ee585;
$color-11:rgb(19, 17, 17);
$color-12:#5c4ee559;
$color-13:#2ECC71;
$color-14:#f0ff08;
$color-15:#a58cd8b0;
$color-16:rgba(46, 204, 112, 0.459);
$color-main:#c62828;//#5C4EE5;
$rkt-viewer-background-color:#272822;
$color-green:#1d7a27;


$color-cine:#f315c4;
$color-flow:#11ea29;
$color-tdi:#e69926;

$purpleMain: #443363;
$purpleHover:#a58cd8;