// GENERAL
.toggle-menu a {
    border: 2px solid #c62828;
    border-radius: 15px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    padding: 3px 8px 3px;
    margin: 5px;
}
.toggle-menu a.selected {
    background-color: #c62828;
}
.toggle-menu a.unselected {
    background-color: transparent;
}

// REFERENCE
.reference.toggle-menu {
    margin: 15px 15px 30px;
    display: inline-block;
}

// MEASUREMENTS
.measurements-header.toggle-menu {
    padding: 12px 10px;

    a {
        padding: 3px 10px;
    }
}
.measurements-anatomies.toggle-menu {
    display: flex;
    justify-content: center;
    margin: 25px 0;

    a {
        margin: 0 10px;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }                   
}