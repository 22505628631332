
.prisma-page {
    width: 100%;
    color: white;
    background-color: #313030;
    overflow: hidden;

    .back-icon {
        padding: 10px 10px 13px;
        overflow: hidden;
        display: flex;

        svg {
            width: 50px;
            height: 50px;
            stroke: red;
            cursor: pointer;
            margin-top: 5px;
            &:hover {
                stroke: #ffffff;
            }
        }
    
        .back-text {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-left: 10px;
        }
    }

    .container {
        background-color: #313030;
        display: flex;
        justify-content: center;
        font-size: 34px;
        font-weight: bold;
        overflow: auto;
        padding: 10px;
        margin-bottom: 15px;

        .studies-list {
            width: 100%;
            font-size: 16px;
            font-weight: normal;
            padding-top: 10px;

            .study-row {
                padding: 0px 30px;

                .header {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);

                    .study-button {
                        text-align: right;

                        span {
                            border: 1px solid black;
                            padding: 5px 10px;
                            background: linear-gradient(#ff8100, #9f5000);
                            border-radius: 10px;
                            cursor: pointer;
                        }
                        &.good span {
                            background: linear-gradient(#03a500, #015200);
                        }
                        &.bad span {
                            background: linear-gradient(#cf0000, #690000);
                        }
                    }
                }

                .content {
                    display: flex;
                    overflow-x: auto;
                    padding: 25px 0 15px;
                    gap: 25px;

                    .image-box {
                        min-width: 540px;
                        position: relative;

                        .image-type-tag {
                            border: 2px solid #666;
                            padding: 5px 9px;
                            border-radius: 10px;
                            font-size: 14px;
                            background-color: black;
                            position: absolute;
                            top: 10px;
                            left: 10px;
                        }

                        .footer {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 10px;
                            margin-top: 10px;
                        }

                        svg {
                            width: 25px;

                            &:first-child {
                                margin-right: 30px;
                            }
                        }
                    }
                }

                .divider {
                    border-top: 1px solid red;
                    border-bottom: 1px solid red;
                    height: 8px;
                    margin: 15px 0 40px;
                }
            }
        }

        .data-management {
            font-size: 16px;
            font-weight: normal;
            width: 100%;
            padding: 10px 40px 0 30px;
            
            span {
                font-weight: bold;
                margin-left: 10px;
            }

            .header {
                display: grid;
                grid-template-columns: 50% 50%;

                .download {
                    text-align: right;

                    span {
                        cursor: pointer;
                        text-decoration: underline;    
                    }
                }
            }

            .page-content {
                display: grid;
                grid-template-columns: 50% 50%;

                .section {
                    margin-top: 30px;
                    
                    span {
                        margin-left: 0;
                    }
    
                    .list {
                        margin: 15px 0 0 20px;
                        line-height: 22px;
                    }
                }    
            }
        }
    }
    
}
