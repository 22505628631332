@import './../chat_component/chat_component.scss';

.validation-banner {
    display: flex;
    justify-content: right;
    padding-top: 15px;

    .finished-alert {
        margin: 0 auto;
        padding: 15px;
        width: 700px;
        text-align: center;
        background-color: #fffcd5;
        color: #000;
        border-radius: 10px;
        font-weight: bold;
    
        .reopen-button {
            float: right;
            border: 1px solid;
            padding: 5px 10px;
            border-radius: 5px;
            margin: -4px 0;
            font-size: 12px;
            cursor: pointer;
            background-color: #ffffff;
        }
    }    
}
.floating-vertically-centered-div-left{
    margin-right:10px; 
    position: absolute; 
    top: 50vh; 
    overflow: 19; 
    background: transparent;
}
.floating-vertically-centered-div-right{
    position: absolute; 
    top: 50vh; 
    overflow: 19; 
    background: transparent;
    right:0;
}
.validation-component {
    display: flex;
    height: 100%;
    margin-top: 10px;

    .navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin: 0 30px;

        svg {
            fill: white;
        }

        &.inactive {
            cursor: auto;

            svg {
                fill: grey;
            }
        }
    }

    .evaluation-container {
        width: 100%;
    }
    .evaluation-area {
        display: grid;
        grid-template-columns: 50% 50%;

        .image-evaluation {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 10px 60px;
            position: relative;
            
            .labels-area {
                display: flex;
                margin-top: 7px;
                align-items: center;
                justify-content: center;
            
                .divider {
                    padding: 7px;
                    color: grey;
                }
            }
            
            .validation-toolbox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                right: 25px;
            
                div {
                    margin-bottom: 20px;
                    cursor: pointer;
                    position: relative;
                }
            
                .inactive {
                    cursor: auto;
            
                    // svg path {
                    //     fill: #222 !important;
                    // }
                }
            
                .bubble {
                    position: absolute;
                    display: flex;
                    top: -4px;
                    justify-content: center;
                    align-items: center;
                    right: -2px;
                    color: black;
                    width: 15px;
                    height: 15px;
                    border-radius: 100px;
                    background-color: #4caf50;
                    font-size: 9px;
                    font-weight: bold;
                }
            }            
        }
        .image-evaluation.missing {
            justify-content: unset;

            .black-box {
                background-color: black;
                width: 100%;
                height: 37vh;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .validate-button {
        text-align: center;
        cursor: pointer;
        position: absolute;
        right: 15px;
        padding: 12px;
        background: black;
        width: 90px;
        font-size: 14px;
        border-radius: 100%;
        &.study {
            top: 72vh;
        }
        &.control {
            top: 60vh;
        }
    }
}

#edit-div {
    .edit-box {
        display: flex;
        position: absolute;
        margin-top: -37px;
        right: 45px;
        background-color: #2b2b2b;
        border: 1px solid white;
        border-radius: 10px;
        // height: 40px;
        align-items: center;
        justify-content: center;
        transform: scaleX(0);
        transform-origin: center right;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        div {
            margin: 0 auto;
        }
        .edit-type{
            border: 2px solid $color-main;
            padding: 4px;
            margin:4px;
            border-radius: 7%;
            white-space: nowrap;
            &:hover{
                background-color: $color-main;
            }
        }
    }
    &.focusable:hover .edit-box {
        transform: scaleX(1);
        opacity: 1;
    }
}

.top-bar-vali{
    display: flex;
    padding: 2px;
    margin: 10px;

    }

.selection-component-vali{
    //display: flex;
    //flex-direction: column;
    padding: 2px;
    background: black; 
    left: 10vw;
    //top:-2vh;
    width: 80vw;  
    //align-content: center; 
    //align-items: center; 
    position: absolute; 
    z-index: 100;
    overflow: hidden;
}