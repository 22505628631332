@import './../data_viewer_component/data_viewer_component.scss';
@import './../image_grid_component/image_grid_component.scss';
@import './../score_component/score_component.scss';
@import './../chat_component/chat_component.scss';

.selection.component {
    display: grid;
    grid-template-rows: 90px 1fr;

    .child {
        display: grid;
        grid-template-columns: 260px 1fr;
        grid-template-rows: calc(100vh - 140px) 1fr;

        .selection.viewer {
            height: 100%;
            padding: 25px;
            display: flex;
            justify-content: center;

            img {
                height: 100%;
            }
        }
    }
}

.comment-window {
    padding: 2px 4px 2px 2px;
    background: black;
    left: 5vw;
    top: 3vh;
    width: 90vw;
    height: 85vh;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 2px;
    position: absolute;
    z-index: 100;

    .labels-area {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    
        .divider {
            padding: 7px;
            color: grey;
        }
    }

    .chat.component {
        height: calc(50vh - 50px);
    }
}

.top-bar {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px,1fr));
    padding-top: 10px;

    .view, .modality {
        border-left: 1px dashed grey;
    }
    .view.alert {
        background-color: #c62828;
    }

    .column {
        padding: 7px;

        .header {
            font-size: 13.5px;
            font-weight: bold;
            border-bottom: 1px solid grey;
            margin: 0 0 5px;
            padding: 0 0 6px 3px;
            display: flex;

            svg {
                width: 20px;
                margin-right: 10px;
            }

            span {
                margin-top: 4px;
            }
        }

        .header-text {
            font-size: 13.5px;
            font-weight: bold;
            border-bottom: 1px solid grey;
            margin: 5px 0;
            padding: 0 0 8px 3px;
        }

        .content {
            padding-top: 6px;
            text-overflow: ellipsis;
        }

        .menu-option {
            width: 100%;
            text-overflow: ellipsis;
            text-align: center;
            color: white;
            height: 32px;
        }
        .menu-option:before {
            border-color: white;
        }
    }
}

.preview-button {
    background-color: #b71c1c;
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-size: 13px;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}
.preview-button:hover {
    background-color: white;
    color: black;
}

.view-alert {
    text-align: center;
    color: red;
    font-style: italic;
    margin-top: 8px;
}

.hover-view {
    position: absolute;
    width: 280px;
    height: 220px;
    display: flex;
    align-items: center;
    border: 4px solid #bbb;
    background-color: black;
    left: 280px;
    top: 20%;
    outline: 2px solid black;

    span {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: white;
        border-radius: 30px;
        padding: 5px 10px;
        color: black;
        font-size: 20px;
        font-weight: bold;
    }
}