.validation-edit .legend {
    left: 10px;
}

.legend {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 60px;

    div {
        display: flex;
    }

    .type-select {
        margin-bottom: 20px;

        .MuiInput-underline:after {
            display: none;
        }
        .MuiInput-root {
            border: 1px solid #eee;
            
            .MuiSelect-root {
                padding-left: 10px;
                font-size: 14px;
                color: #eee;
            }
            .MuiSelect-icon {
                color: #eee;
            }
        }
        .MuiInput-root.Mui-disabled {
            border-color: #999;

            .MuiSelect-root {
                color: #999;
            }
            .MuiSelect-icon {
                color: #999;
            }
        }
    }

    // .legend-header {
    //     margin-bottom: 15px;
    //     font-size: 14px;
    //     color: #999;
    // }

    .circle {
        width: 10px;
        height: 10px;
        margin-top: 2px;
        margin-right: 8px;
        border-radius: 100%;
        border: 1px solid;
    }

    .text {
        margin-bottom: 10px;
        font-size: 14px;
    }
}

.shapes {
    position: absolute;
    top: 20%;
    right: 90px;
    z-index: 20;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .shape {
        border: 2px solid #666;
        width: 100px;
        height: 50px;
        padding: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    .shape.active {
        cursor: pointer;
    }

    svg {
        margin-bottom: 10px;
        cursor: pointer;
        width: 35px;
    }
}

#draggable-box {
    padding: 20px;
    z-index: 10;
    position: absolute;
    top: 220px;
    left: 40px;
    cursor: pointer;
    background: rgb(39, 40, 34);
    min-width: 200px;
    border: 1px solid white;
    display: flex;
}

.caliper-box {
    .notice {
        font-style: italic;
        text-align: center;
        width: 250px;
        line-height: 25px;
    }
    .option-checkbox {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ccc;
        margin-bottom: 20px;

        input {
            width: 13px;
            // background-color: rgb(39, 40, 34);
            margin-right: 10px;
        }
    }
    .type-select {
        margin-bottom: 20px;

        .MuiInput-root {
            margin-left: 5px;
            
            .MuiSelect-root {
                padding-left: 10px;
                color: #ddd;
            }
        }
    }
    .save-button {
        width: 80px;
        margin: 20px auto 0;
        background-color: #ddd;
        border-radius: 5px;
        padding: 10px;
        color: #333;
        font-weight: bold;
        text-align: center;

        &:hover {
            background-color: #fff;
        }
    }
    .hidden {
        display: none;
    }
}

// Doppler
.progress {
    font-size: 20px;
    position: absolute;
    top: 20px;
    right: 5%;
}

// 2D
.slider {
    z-index: 10;
    position: absolute;
    bottom: 250px;
    right: 120px;
    width: 100px; 
}
.time-events {
    position: absolute;
    bottom: 20px;
    right: 10px;

    div {
        display: inline-block;
        width: 120px;
        height: 50px;
        background-color: none;
        border-radius: 10px;
        border: #fff solid 3px;
        cursor: pointer;
        padding: 5px;
        text-align: center;
        margin-right: 10px;
        font-size: 14px;
        line-height: 18px;
    }
}
.time-events.validation {
    left: 20px;

    div {
        width: 80px;
        height: 35px;
    }
}

