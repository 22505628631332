.stats.component {
    padding: 30px;
    overflow: auto;

    .block-header {
        display: grid;
        grid-template-columns: 1fr 350px 350px;
        border-bottom: 2px solid red;
        margin-bottom: 30px;

        .block-title {
            font-weight: bold;
            font-style: italic;
            padding: 10px 20px;
            cursor: pointer;
            width: 100%;
        }
        .filter-box {
            display: grid;
            grid-template-columns: 1fr 110px 20px 110px;
            
            span {
                margin: auto;
                font-size: 14px;

                &:first-child {
                    width: 100%;
                    text-align: right;
                    padding-right: 5px;
                }
            }
            .date-picker {
                margin: auto 0;
                display: flex;
                justify-content: center;

                input {
                    margin: auto;
                    border-radius: 5px;
                    width: 100px;
                    height: 30px;
                    padding: 0;
                    text-align: center;
                    font-size: 14px;
                }
                .react-datepicker__day--keyboard-selected {
                    background-color: #ff9999;

                    &:hover {
                        background-color: #a52121;
                    }
                }
                .react-datepicker__day--selected {
                    background-color: #a52121;
                }
            }
        }
    }

    .block-content {
        padding: 20px;
        flex-direction: column;
        display: flex;
    }
    .block-content.data {
        padding: 0px 20px;

        span {
            font-weight: bold;
            margin-left: 10px;
        }

        .stats-grid {
            display: grid;
            grid-template-columns: 65% 35%;
            margin: 30px 0;

            img {
                width: 500px;
            }

            table {
                border: 1px solid white !important;
                margin-top: 15px;

                th {
                    padding: 10px 20px;
                    border: 1px solid white;
                    font-weight: bold;
                }

                td {
                    padding: 10px 20px;
                    border: 1px solid #888;

                    &:first-child {
                        font-weight: bold;
                        border: 1px solid white;
                    }
                }
            }
        }
    }
    .block-content.quality {
        .quick-qc {
            color: #feb;
            &.per-image {
                margin-top: 30px;
                border: 1px solid #feb;
                padding: 15px 15px 23px;
                width: 80%;
            }
        }
        .row {
            display: grid;
            grid-template-columns: 19% 27% 27% 27%;
            &:first-child {
                margin: 10px 0px 30px;
            }
            &:not(:first-child) {
                border-top: 1px solid grey;
                padding: 20px 0px;
            }

            span {
                font-weight: bold;
            }

            .column {
                display: flex;
                align-items: center;
                flex-direction: column;
                grid-gap: 10px;
                padding: 0px 30px;

                span {
                    border-bottom: 1px solid;
                    width: 100%;
                    padding-bottom: 5px;
                    text-align: center;
                }

                & > div {
                    display: grid;
                    width: 100%;
                    grid-template-columns: 65% 35%;
                    align-items: center;
                    text-align: center;
                    padding-right: 2%;

                    .point-title {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}