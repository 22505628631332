@import './../data_viewer_component/DICOM/dicom_viewer_component.scss';
@import './../data_viewer_component/data_viewer_component.scss';
@import './../image_grid_component/image_grid_component.scss';

.analysis.component {
    display: grid;
    overflow: hidden;
    flex: 1 1 auto;
    grid-template-columns: 150px 1fr;

    .comment-window {
        display: flex;

        .chat.component {
            height: unset;
        }
    }
}