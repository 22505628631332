// PREVIEW
.preview.grid {
    padding: 20px;
    overflow: auto;
    
    .grid-images {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        grid-gap: 50px;
        overflow: visible;

        .grid-image {
            position: relative;
            justify-content: center;
            display: flex;

            img.image {
                width: 270px;
                height: 200px;
            }

            .hover {
                position: absolute;
                width: 270px;
                height: 200px;
                cursor: pointer;
            }
        }

        .grid-selected img.image {
            outline: 3px solid #1769aa !important;
        }
    }
}

// OVERVIEW
.overview.grid {
    padding: 20px;
    background-color: rgb(56, 56, 56);
    margin: 10px 20px;
    border-radius: 10px;
    min-height: 120px;
    max-height: 120px;
    overflow: auto;
    
    .grid-images {
        display: grid;
        grid-template-columns: repeat( auto-fill, 100px );
        grid-gap: 10px;
        overflow: visible;

        .grid-image {
            width: 100px;
            height: 75px;
            background-color: black;
            position: relative;

            img.image {
                outline: 2px solid #b71c1c;
            }

            .hover {
                position: absolute;
                top: 0px;
                width: 100px;
                height: 75px;
                cursor: pointer;
            }
        }

        .grid-selected {
            outline: 3px solid white !important;
        }
    }
}

// SELECTION
.selection.grid {
    padding: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    grid-row: 1 / span 1;

    .grid-images {
        display: grid;
        grid-template-columns: repeat( auto-fill, 98px );
        grid-gap: 25px;

        .grid-image {
            position: relative;

            img.image {
                outline: 2px solid #b71c1c;
                background-color: black;
                width: 98px;
                height: 74px;
            }

            .reference-icon {
                position: relative;
                float: left;
                margin-top: -73px;

                svg {
                    margin: 1.75px;
                    width: 15px;
                    height: 15px;
                    fill: #c62828;
                    background-color: black;
                }
            }

            .bullet-icon {
                position: relative;
                float: left;
                left: 3px;
                margin-top: -18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                width: 15px;
                height: 15px;
                border-radius: 100px;
                background-color: #cb6700;
                font-size: 10px;
                font-weight: bold;

                &.scored {
                    background-color: #080;
                }
            }

            .tag {
                border: 2px solid white;
                border-radius: 5px;
                background-color: #222c;
                font-size: 11.5px;
                padding: 3px 3px 3px 5px;
                position: absolute;
                bottom: 26px;
                right: 2px;
                max-width: 94px;
                display: flex;
                align-items: center;
                z-index: 2;

                .delete-button {
                    font-size: 12px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    opacity: 0.7;
                    color: white;
                    margin-left: 3px;
                    line-height: 8px;
                    border: 2px solid white;
                    border-radius: 15px;
                    padding: 0.5px 1px;
                }

                .tag-long {
                    display: none;
                    width: 100px;
                    background-color: #222;
                    color: #fff;
                    text-align: center;
                    padding: 6px;
                    border-radius: 6px;
                    position: absolute;
                    font-size: 9px;
                    margin: -50px 0 0 -40px;
                }
                .tag-long:after {
                    content: " ";
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    margin-left: -5px;
                    border: 5px solid transparent;
                    border-top-color: #222;
                }
            }
            .tag:hover .tag-long {
                display: block;
            }

            .hover {
                position: absolute;
                top: 0px;
                width: 98px;
                height: 74px;
                cursor: pointer;
            }
            .hover:hover {
                background-color: #f003;
            }

            .footer {
                font-size: 16px;
                text-align: center;
                padding-top: 8px;
            }
        }

        .grid-selected {
            
            img.image {
                outline: 3px solid white !important;
            }

            .footer {
                color: #fb8c00;
                font-weight: bold;
            }
        }
    }
}

// CYCLE TIMING / SEGMENTATION
.cycletiming.grid, .segmentation.grid {
    padding: 15px;
    overflow-y: scroll;
    width: 150px;
    height: 100%;

    .grid-images {
        display: grid;
        grid-gap: 30px;
        overflow: visible;

        .grid-image {
            outline: 1px solid #b71c1c;
            background-color: black;
            position: relative;
            border-radius: 5px;

            img.image {
                height: 88px;
                border-radius: 5px;
            }

            .check-icon {
                position: absolute;
                border-radius: 100%;
                background-color: #2ecc71;
                width: 20px;
                height: 20px;
                margin: -83px 0 0 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .modality-icon {
                margin-right: 3px;
                margin-top: -85px;
            }

            .hover {
                position: absolute;
                top: 0px;
                width: 117px;
                height: 88px;
                cursor: pointer;
            }

            .footer {
                font-size: 13px;
                font-weight: bold;
                color: black;
                text-align: center;
                padding: 5px;
                background-color: #b71c1c;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        .grid-selected {
            outline: 2px solid white !important;

            &:before {
                content: " ";
                position: absolute;
                top: -5px;
                left: -5px;
                right: -5px;
                bottom: -5px;
                border-radius: 7px;
                outline: 2px solid white;
            }
        }
    }
}

// GENERAL
.modality-icon {
    position: relative;
    background-color: rgba(35,35,35,.8196078431372549);
    float: right;
    margin-top: -73px;
    width: 24.5px;
    height: 24.5px;

    svg {
        width: 20px;
        margin: 2px;
    }
}