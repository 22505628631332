.study-state {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    
    div {
        margin-left: 10px;
        background-color: #000;
        padding: 5px 12px;
        border: 2px solid #aaa;
        border-radius: 15px;
        opacity: 0.85;
    }
    .manual {
        margin-left: 15px;
        opacity: 1;
        cursor: pointer;
    }
    .bad {
        background-color: #b00;
    }
    .good {
        background-color: #090;
    }
    .none {
        background-color: #f80;
    }
}

.reference.study-state {
    float: right;
    margin: 12px 15px;
}

.validation.study-state {
    margin: 5px 30px 0 -175px;
}