.score.component {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    padding: 30px;
    background-color: #141410;

    .title {
        line-height: 30px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        margin-bottom: 20px;
        border-bottom: 1px solid #444;
    }

    .evaluation-box {
        overflow: auto;
        padding-right: 15px;
        margin-right: -15px;
        height: calc(85vh - 110px);

        .notice {
            padding: 12px;
            font-size: 14px;
            font-style: italic;
            margin-bottom: 20px;
            border-radius: 5px;
            font-weight: bold;

            &.yes {
                color: #080;
                border: 1px solid #080;
                background: #cfffd1;
            }
            &.no {
                color: #f60;
                border: 1px solid #f60;
                background: #ffd7b3;
            }    
        }

        .section {
            border-bottom: 1px solid;
            margin-top: 30px;
            margin-bottom: 5px;
            padding-bottom: 5px;
            text-transform: uppercase;
            font-weight: bold;
        }

        .item {
            padding: 10px;
            display: flex;

            &.odd {
                background: #222;
            }

            .item-name {
                width: 280px;
                padding-right: 20px;
            }

            .item-option {
                text-transform: uppercase;
                width: 90px;
                padding: 0px 5px;
                text-align: center;
                white-space: nowrap;
                line-height: 16px;
                margin-right: 40px;
                cursor: pointer;

                &:last-child {
                    margin-right: 10px;
                }

                &.selected {
                    outline: 2px solid;
                    border-radius: 5px;
                    outline-offset: 5px;
                    cursor: auto;
                }
            }
        }

        .submit-button {
            cursor: pointer;
            width: 150px;
            margin-top: 40px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background-color: #f44336;
            border-radius: 5px;

            &.disabled {
                background-color: grey;
                cursor: auto;
            }
        }
    }
}