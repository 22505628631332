// EMPTY VIEWER
.empty.viewer {
    display: flex;
    align-items: center;
    justify-content: center;

    .child {
        width: 80%;
        height: 90%;
        border: 2px dashed #c62828;
        border-radius: 70px;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .camera-icon {
            width: 90px;
            height: 90px;
            fill: white; 
            opacity: 0.7;
            margin-bottom: 30px;
        }
    }
}

// ACTION TITLES
.viewer .titles {
    position: absolute;
    font-size: 20px;
    display: grid;
    grid-template-columns: auto auto;
    background-color: black;
    padding: 20px 5%;
    top: 0px;
    width: 100%;

    .right {
        text-align: right;
    }
}

// ALERTS
#alert {
    position: absolute;
    bottom: 20px;
    width: 80%;
    text-align: center;
    background-color: #fff;
    padding: 7px;
    font-size: 14px;
    border-radius: 10px;
    border: 2px solid white;
    font-weight: bold;
    display: none;
}
#alert.soft {
    color: #f37f0c;
    border-color: #f37f0c;
}
#alert.hard {
    color: #f00;
    border-color: #f00;
}

// IMAGE MENU
.validation-edit .image-menu {
    right: 0px;
}
.image-menu {
    position: absolute;
    right: 10px;
    height: 100%;
    display: flex;
    align-items: center;

    .child {
        display: grid;
        grid-gap: 20px;
        width: 35px;
        position: relative;
        grid-template-columns: unset !important;
        grid-template-rows: unset !important;

        div {
            text-align: center;
            width: 35px;
        }
        div.text {
            height: 35px;
            border: 3px solid #222;
            color: #222;
            display: flex;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            cursor: context-menu;
        }
        div.text.active {
            border-color: #c62828;
            color: #c62828;
            cursor: pointer;
        }
        div.text.active:hover {
            border-color: white;
            color: white;
        }

        svg {
            fill: #222;
        }
        svg.active {
            fill: #c62828;
            cursor: pointer;
        }
        svg.active-green {
            fill: #00aa00;
            cursor: pointer;
        }
        svg.active:hover, svg.active-green:hover {
            fill: white;
        }

        .comment-bubble {
            position: absolute;
            right: -2px;
            margin-top: -40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: black;
            width: 15px;
            height: 15px;
            border-radius: 100px;
            background-color: white; //#4caf50;
            font-size: 9px;
            font-weight: bold;
        }

        #correction {
            .correction-box {
                display: flex;
                flex-direction: column;
                position: absolute;
                width: 250px;
                height: 140px;
                margin-top: -37px;
                right: 45px;
                padding-top: 10px;
                background-color: #2b2b2b;
                border: 1px solid white;
                border-radius: 10px;
                align-items: center;
                justify-content: center;
                transform: scaleX(0);
                transform-origin: center right;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                div {
                    width: 150px;
                }
            }
            &.focusable:hover .correction-box {
                transform: scaleX(1);
                opacity: 1;
            }
        }

        #pattern {
            .pattern-box {
                display: flex;
                position: absolute;
                margin-top: -37px;
                right: 45px;
                background-color: #2b2b2b;
                border: 1px solid white;
                border-radius: 10px;
                height: 40px;
                width: unset;
                padding: 2px;
                grid-gap: 2px;
                align-items: center;
                justify-content: center;
                transform: scaleX(0);
                transform-origin: center right;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                div {
                    margin: 0 auto;
                }
            }
            &.focusable:hover .pattern-box {
                transform: scaleX(1);
                opacity: 1;
            }
        }

        #qc {
            .qc-box {
                display: flex;
                position: absolute;
                margin-top: -37px;
                right: 40px;
                background-color: #2b2b2b;
                border: 1px solid white;
                border-radius: 10px;
                height: 40px;
                width: unset;
                padding: 5px;
                align-items: center;
                justify-content: center;
                transform: scaleX(0);
                transform-origin: center right;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                &.hidden {
                    display: none;
                }

                svg {
                    fill: #0d0;
                }
            }
            &.focusable:hover .qc-box {
                transform: scaleX(1);
                opacity: 1;
            }
        }

        #image-options {
            .image-options-box {
                display: flex;
                position: absolute;
                margin-top: -37px;
                right: 45px;
                background-color: #2b2b2b;
                border: 1px solid white;
                border-radius: 10px;
                height: 40px;
                width: unset;
                padding: 3px 5px;
                grid-gap: 4px;
                align-items: center;
                justify-content: center;
                transform: scaleX(0);
                transform-origin: center right;
                opacity: 0;
                transition: all 0.2s ease-in-out;

                div {
                    margin: 0 auto;
                }
            }
            &.focusable:hover .image-options-box {
                transform: scaleX(1);
                opacity: 1;
            }
            .metadata-box {
                position: fixed;
                z-index: 100;
                background-color: #000a;
                height: 100vh;
                width: 100vw;
                left: 0;
                top: 0;
                display: flex;
                justify-content: center;
                padding: 40px 0 40px 40px;

                .metadata-content {
                    width: 45vw;
                    height: 100%;
                    background-color: #2b2b2b;
                    padding: 12px;
                    border: 1px solid white;
                    border-radius: 10px;
                    margin-right: 5px;

                    .rows {
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        margin: 0;
                        text-align: left;
                        white-space: nowrap;

                        .key {
                            font-weight: bold;
                            font-style: italic;
                            color: #bbb;
                        }
                    }

                    ul {
                        list-style-type: unset;
                        list-style-position: inside;

                        li li {
                            list-style-type: circle;
                        }

                        li li li {
                            list-style-type: square;
                        }
                    }
                }

                svg {
                    fill: white;
                }
            }
        }

        #help-box {
            display: grid;
            background-color: #2b2b2b;
            position: absolute;
            z-index: 100;
            bottom: 40px;
            right: 12px;
            padding: 12px;
            border: 1px solid white;
            border-radius: 10px;
            font-style: italic;
            grid-gap: 10px;
            grid-template-columns: 70px auto;
            width: 230px;
            transform: scale(0);
            transform-origin: bottom right;
            opacity: 0;
            transition: all 0.2s ease-in-out;

            .help-left {
                justify-content: end;
                align-items: center;
                display: flex;
                width: 100%;
            }
            
            .help-right {
                text-align: left;
                color: #999;
                font-size: 15px;
                width: 100%;
            }
        }
        
        #help-button:hover + #help-box {
            transform: scale(1);
            opacity: 1;
        }
    }
}