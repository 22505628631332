@import './../toggle_menu_component/toggle_menu_component.scss';

.measurements.component {
    position: relative;
    overflow: auto;

    .header-report {
        height: 70px;
        display: flex;
        padding-left: 20px;

        .menu-title {
            padding: 15px 10px;
            text-align: right;
            font-size: 13px;
        }
    }
    .header-menus {
        height: 70px;
        position: absolute;
        top: 0;
        right: 0;
        display: grid;
        grid-template-columns: minmax(200px, 1fr) 250px 150px 180px 100px;

        .menu-title {
            padding: 15px 10px;
            text-align: right;
            font-size: 13px;
        }
        .export-button {
            text-align: center;
            padding: 5px 15px 0px;

            svg {
                cursor: pointer;
            }
        }
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

        &.summary {
            grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
        }

        .side {
            text-align: center;

            &.right {
                border-right: solid white 2px;
            }

            .side-title {
                font-weight: bold;
                margin-bottom: 15px;
            }
        }
        
        .anatomy-box {
            border-radius: 7px;
            align-items: center;
            margin: 15px 30px;
            border: solid grey 2px;
            min-height: 100px;
            padding-bottom: 4px;

            .images-area {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(11vw, 1fr));
                width: 100%;
                justify-items: center;
                margin-bottom: 30px;

                .image-container {
                    margin-top: 20px;
                    width: 10vw;
                    height: 14vh;
                }
            }
        }
    }
}