.chat.component {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    padding: 30px;
    background-color: rgb(39 40 34);

    .title {
        line-height: 30px;
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        margin-bottom: 30px;
        border-bottom: 1px solid #444;
    }

    .comments-box {
        height: 100%;
        margin-bottom: 15px;
        overflow: auto;

        .comment {
            display: flex;
            margin-bottom: 20px;

            div {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .initials {
                align-items: center;
                margin-right: 10px;
                min-width: 40px;
                height: 40px;
                font-weight: bold;
                border-radius: 100%;
                background-color: #2196f3;
            }

            .date {
                color: grey;
                font-size: 10px;
                font-style: italic;
                margin-top: 8px;
            }

            .trash-button {
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }

    .new-comment {
        display: flex;

        textarea {
            border: 1px solid rgb(244, 67, 54);
            margin: 0 5px 0 0;
            border-radius: 5px;
        }

        .send-button {
            cursor: pointer;
            min-width: 50px;
            height: 50px;
            text-align: center;
            padding: 10px 5px;
            line-height: 15px;
            background-color: rgb(244, 67, 54);
            border-radius: 5px;
        }
    }
}