@import './../../colors.scss';

.login-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .platform-name.big {
        width: 525px;
        height: 295px;
    }
    .login-button {
        font-size: 12pt;
        color:white;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: $color-normal;
        cursor: pointer;
        border: 5px solid transparent;
        overflow: hidden;
        z-index: 5;
        align-items: center;
        display: flex;
        margin-top:-4px;
        &:hover{
            background-color: transparent;
            border: 5px solid $color-normal;
        }
        
        svg {
            margin-left: 25px;
            stroke: white;
            width: 90px;
            height: 90px;
        }
    }
}

