@import './../../colors.scss';

.accept-terms-page {
    padding: 5vh 0;
    height: 100vh;
    width: 80vw;
    max-width: 900px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;

    .title {
        color: #f99;
        font-weight: bold;
        font-size: 20px;
    }

    .text-box {
        margin: 20px 0;
        border: 2px solid lightgray;
        border-radius: 15px;
        width: 100%;
        padding: 20px 15px 20px 20px;
        line-height: 20px;
        text-align: justify;

        p {
            line-height: 20px;
        }
        a {
            color: #f99;
        }
        div {
            height: 60vh;
            overflow-y: scroll;
            padding-right: 15px;
        }
        table td {
            padding: 10px;
            border: 1px solid gray;

            &.table-title {
                color: #f99;
                font-weight: bold;
            }
        }
        ul {
            font-size: inherit;
        }
    }

    .option-checkbox {
        display: grid;
        grid-template-columns: 26px 1fr;
        margin-bottom: 10px;
        width: 100%;

        input {
            margin-right: 10px;
            width: 16px;
            height: 16px;
        }
    }

    button {
        cursor: auto;
        padding: 12px 15px;
        font-weight: bold;
        font-size: 17px;
        border-radius: 10px;
        margin: 10px;
        border: 1px solid #000;

        &.exit {
            cursor: pointer;
            background-color: #888;
        }
        &.accept {
            background-color: #555;
            color: #aaa;
        }
        &.accept.active {
            cursor: pointer;
            background-color: #800;
            color: inherit;
        }
    }
}

