.alert-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;

    .alert {
        width: 40%;
        padding: 30px;
        line-height: 25px;
        background-color: #ddd;
        color: #900;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: justify;

        .options{
            display: flex;
            grid-gap: 15px;
            margin-top: 10px;
        }

        button {
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
            padding: 10px;
            border-radius: 10px;
            line-height: 20px;
            margin-top: 10px;
        }
    }
}
